<app-nav titulo="Detalles del crédito" descripcion="Aquí podrá ver todos los datos que tenemos sobre el crédito seleccionado.">
    <div class="loan-details" *ngIf="loan">
        <app-data-printer [categories]="categories"></app-data-printer>
        <button mat-raised-button class="fees-button" color="primary" (click)="openFeesModal()" [disabled]="!plan?.fees">
            Ver plan de pagos
        </button>
        <button mat-raised-button color="primary" class="scoring-button" routerLink="/informes/{{loan.scoring.id}}">
            Ver detalles del scoring
        </button>
        <div class="buttons">
            <button mat-raised-button class="reject-button" color="accent" (click)="reject()" *ngIf="loan.state === 'revision'">
                Rechazar
            </button>
            <button mat-raised-button class="approve-button" color="primary" (click)="approve()" *ngIf="loan.state === 'revision'">
                Aprobar
            </button>
        </div>
        <div class="buttons">
            <button mat-raised-button class="transfer-button" color="primary" (click)="reportTransfer()" *ngIf="loan.state === 'awaiting_transfer'">
                Informar depósito en cuenta
            </button>
        </div>
    </div>
</app-nav>

<ng-template #downloadDocument let-document="document">
    <button mat-raised-button class="download-document-button" color="primary" (click)="downloadDoc(document)">
        Descargar
    </button>
</ng-template>

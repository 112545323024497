import { Plan } from './../models/plan.model';
import { AWSRequest } from './../models/aws-request.model';
import { UtilsService } from './utils.service';
import { SortConfiguration } from './../components/generics/data-table/data-table.component';
import { ID } from './../models/id.model';
import { Loan, LoanState } from './../models/loan.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Document } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService
    ) { }

    getLoans(page: number, itemsPerPage: number, sorts: SortConfiguration[], extraParams: any) {
        const url = '/loans';
        const headers = {};
        const params = {
            'sort-by': this.utilsService.generateSortQueryParam(sorts),
            'page-number': String(page),
            'page-size': String(itemsPerPage)
        };

        Object.assign(params, JSON.parse(JSON.stringify(extraParams)));

        return this.http.get<Loan[]>(url, { headers, observe: 'response', params });
    }

    getLoanById(id: ID) {
        return this.http.get<Loan>(`/loans/${id}`);
    }

    approveRevision(id: ID) {
        return this.http.post<Loan>(`/loans/${id}/revision/approval`, {});
    }

    rejectRevision(id: ID, reason: string) {
        return this.http.post<Loan>(`/loans/${id}/revision/approval`, { reason });
    }

    reportTransfer(id: ID) {
        return this.http.post<Loan>(`/loans/${id}/transfer`, {});
    }

    getLoanDocuments(id: ID) {
        return this.http.get<Document[]>(`/loans/${id}/documents`);
    }

    downloadDocument(loanId: ID, documentId: ID) {
        return this.http.get<AWSRequest>(`/loans/${ loanId }/documents/${ documentId }`);
    }

    getPlan(loanId: ID) {
        return this.http.get<Plan>(`/loans/${ loanId }/plan`);
    }

    translateStatus(status: LoanState) {
        if (status === 'documentation') {
            return 'Documentación pendiente';
        } else if (status === 'plan') {
            return 'Aprobación pendiente';
        } else if (status === 'revision') {
            return 'En revisión';
        } else if (status === 'bank_account_info') {
            return 'Información bancaria pendiente';
        } else if (status === 'signature') {
            return 'Firma pendiente';
        } else if (status === 'awaiting_transfer') {
            return 'Depósito bancario en trámite';
        } else if (status === 'active') {
            return 'Activo';
        } else if (status === 'rejected') {
            return 'Rechazado';
        }
    }
}

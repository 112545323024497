<div class="confirm-dialog">
    <h2>{{ data.titulo }}</h2>
    <span>{{ data.descripcion }}</span>
    <mat-form-field class="garantor-form-field no-bottom-spacing-form-field input" appearance="outline" *ngIf="data.input">
        <mat-label>{{ data.input }}</mat-label>
        <input matInput [(ngModel)]="inputValue">
    </mat-form-field>
    <div class="buttons">
        <button mat-button *ngIf="data.botonCancelar" color="accent" class="garantor-button mini" (click)="cerrarDialogo(false)">{{ data.botonCancelar }}</button>
        <button mat-raised-button *ngIf="data.botonAceptar" color="primary" class="garantor-raised-button mini" (click)="cerrarDialogo(true)">{{ data.botonAceptar }}</button>
    </div>
</div>

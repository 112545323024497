import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Session } from '../models/session.model';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})

export class AuthService implements CanActivate {

    private storageTokenKey = 'auth';
    private userKey = 'user';

    constructor(private http: HttpClient, private router: Router) { }

    login(email: string, password: string) {
        return this.http.post<Session>('/users/login', { email, password }, { headers: {'x-recaptcha-hash': 'Recaptcha test'} });
    }

    getLogged(): boolean {
        return !!localStorage.getItem(this.storageTokenKey);
    }

    getToken(): string {
        return localStorage.getItem(this.storageTokenKey);
    }

    saveToken(token: string) {
        localStorage.setItem(this.storageTokenKey, token);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (route.data && route.data.requireAuth === true) {
            if (this.getLogged()) {
                return true;
            }
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } else if (route.data && route.data.requireAuth === false) {
            if (this.getLogged()) {
                this.router.navigate(['/home']);
                return false;
            }
            return true;
        } else {
            return true;
        }
    }

    logout() {
        localStorage.removeItem(this.storageTokenKey);
    }

    saveUser(user: User) {
        localStorage.setItem(this.userKey, JSON.stringify(user ?? {}));
    }

    getUser(): User {
        if (localStorage.getItem(this.userKey)) {
            return JSON.parse(localStorage.getItem(this.userKey)) as User;
        } else {
            return null;
        }
    }
}

import { ReportService } from './../../../services/report.service';
import { Scoring } from './../../../models/scoring.model';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../generics/data-printer/data-printer.component';
import { ErrorResponse } from '../../../models/error.model';

@Component({
    selector: 'app-report-details',
    templateUrl: './report-details.component.html',
    styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {

    categories: Category[] = [];
    report: Scoring;

    constructor(
        private reportService: ReportService,
        private route: ActivatedRoute,
        public utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.reportService.getReportById(params.id).subscribe(report => {
                    this.report = report;
                    this.generateCategories(report);
                }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener reporte', error.error?.message));
            }
        });
    }

    generateCategories(report: Scoring) {
        this.categories = [
            { name: 'Información general', items: [
                { label: 'Fecha de ejecución', value: report.added, type: 'datetime' },
                { label: 'CUIT-CUIL', value: report.identityCard, type: 'number' },
                { label: 'Sexo', value: report.gender, type: 'text' },
                { label: 'Dictámen', value: report.dictum, type: 'text' },
                { label: 'Error', value: report.error, type: 'text' },
            ]}
        ];
        if (report.parameters) {
            this.categories.push({
                name: 'Parámetros',
                items: Object.keys(report.parameters).map(key => ({
                    label: key,
                    value: report.parameters[key]
                }))
            });
        }
        if (report.explanation && Object.keys(report.explanation).length) {
            this.categories.push({
                name: 'Explicación',
                items: Object.keys(report.explanation).map(key => ({
                    label: key,
                    value: report.explanation[key]
                }))
            });
        }
        if (report.variables) {
            if (report.variables.nosis && Object.keys(report.variables.nosis).length) {
                this.categories.push({
                    name: 'Datos Nosis',
                    items: Object.keys(report.variables.nosis).map(key => ({
                        label: key,
                        value: report.variables.nosis[key]
                    }))
                });
            }
            
            if (report.variables.bcra) {
                report.variables.bcra.forEach((cuenta, index) => {
                    this.categories.push({
                        name: `Datos BCRA - Cuenta ${index}`,
                        items: Object.keys(cuenta).map(key => ({
                            label: key,
                            value: cuenta[key]
                        }))
                    });
                });
            }
        }
    }

}

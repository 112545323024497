import { ReportService } from './../../services/report.service';
import { Scoring } from './../../models/scoring.model';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ErrorResponse } from '../../models/error.model';
import { UtilsService } from '../../services/utils.service';
import { Loan } from '../../models/loan.model';
import { PaginatorStatus, SortConfiguration, DataTableColumn, SortEvent, MenuButton } from '../generics/data-table/data-table.component';
import { SortDirection } from '@swimlane/ngx-datatable';
import { ID } from '../../models/id.model';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    paginatorStatus: PaginatorStatus = {
        itemsPerPage: 5,
        page: 0,
        totalElementCount: 0
    };

    columns: DataTableColumn[] = [
        { name: 'Fecha de ejecución', prop: 'added', type: 'datetime' },
        { name: 'CUIT-CUIL', prop: 'identityCard', type: 'number' },
        { name: 'Sexo', prop: 'gender', type: 'text' },
        { name: 'Dictámen', prop: 'dictum', type: 'text' },
        { name: 'Error', prop: 'error', type: 'text' },
    ];

    sorts: SortConfiguration[] = [{ dir: SortDirection.desc, prop: 'added' }];

    menuButtons: MenuButton<Loan>[] = [
        { label: 'Ver detalles', action: loan => this.router.navigate(['informes', loan.id]) }
    ];

    reports: Scoring[];

    constructor(
        private reportService: ReportService,
        private utilsService: UtilsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.getReports(params.userId);
        });
    }

    getReports(userId?: ID) {
        this.reportService.getReports(this.paginatorStatus.page, this.paginatorStatus.itemsPerPage, this.sorts, userId ? { 'user-id': userId } : {})
            .subscribe(response => {
                this.paginatorStatus.totalElementCount = parseInt(response.headers.get('pagination-total-count') ?? '0', 10);
                this.reports = response.body;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener informes', error.error?.message));
    }

    updateReports(event: PageEvent) {
        this.paginatorStatus.itemsPerPage = event.pageSize;
        this.paginatorStatus.page = event.pageIndex;
        this.getReports();
    }

    updateSort(event: SortEvent) {
        this.sorts = event.sorts;
        this.getReports();
    }

}

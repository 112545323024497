import { ColumnMode } from '@swimlane/ngx-datatable';
import { UtilsService } from './../../services/utils.service';
import { DataTableColumn } from './../../components/generics/data-table/data-table.component';
import { Fee } from './../../models/plan.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-fees-list-dialog',
    templateUrl: './fees-list-dialog.component.html',
    styleUrls: ['./fees-list-dialog.component.scss']
})
export class FeesListDialogComponent implements OnInit {

    columns: DataTableColumn[] = [
      { name: 'Nro de cuota', prop: 'feeNumber', type: 'number' },
      { name: 'Fecha de vencimiento', prop: 'dueDate', type: 'date' },
      { name: 'Valor de cuota', prop: 'pureFee', type: 'money' },
      { name: 'Interés de la cuota', prop: 'interests', type: 'money' },
      { name: 'Impuestos', prop: 'taxes', type: 'money' },
      { name: 'Gastos administrativos', prop: 'expenses', type: 'money' },
      { name: 'Total a pagar', prop: 'totalFee', type: 'money' },
      { name: 'Estado', prop: 'state', parseValue: value => value.toUpperCase() },
    ];

    ColumnMode = ColumnMode;

    constructor(
        public dialogRef: MatDialogRef<FeesListDialogComponent>,
        private utilsService: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: { fees: Fee[] }
    ) { }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    downloadCSV() {
        this.utilsService.tableToCSV(this.data.fees, this.columns, 'Plan de pagos');
    }

}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    cloneReq: any;

    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = request.headers;

        if (this.authService.getLogged() && !request.url.includes('amazonaws.com')) {
            headers = headers.append('Authorization', this.authService.getToken());
        }

        this.cloneReq = request.clone({
            url: (request.url && request.url.startsWith('/')) ? environment.apiUrl + request.url : request.url,
            headers
        });

        return next.handle(this.cloneReq).pipe(tap(
            _ => {},
            error => {
                if (error.status === 403) {
                    this.authService.logout();
                    location.reload();
                }
            }
        ));
    }
}

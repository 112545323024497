import { Scoring } from './../models/scoring.model';
import { Injectable } from '@angular/core';
import { ID } from '../models/id.model';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { SortConfiguration } from '../components/generics/data-table/data-table.component';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService
    ) { }

    getReports(page: number, itemsPerPage: number, sorts: SortConfiguration[], extraParams: any) {
        const url = '/scorings';
        const headers = {};
        const params = {
            'sort-by': this.utilsService.generateSortQueryParam(sorts),
            'page-number': String(page),
            'page-size': String(itemsPerPage)
        };

        Object.assign(params, JSON.parse(JSON.stringify(extraParams)));

        return this.http.get<Scoring[]>(url, { headers, observe: 'response', params });
    }

    getReportById(id: ID) {
        return this.http.get<Scoring>(`/scorings/${id}`);
    }
}

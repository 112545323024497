import { FeesListDialogComponent } from './dialogs/fees-list-dialog/fees-list-dialog.component';
import { SexSelectorComponent } from './components/generics/sex-selector/sex-selector.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { UtilsService } from './services/utils.service';
import { LoginComponent } from './components/login/login.component';
import { MaterialModule } from './material-module';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HomeComponent } from './components/home/home.component';
import { NavComponent } from './nav/nav.component';
import { DataTableComponent } from './components/generics/data-table/data-table.component';
import { ImageUploaderComponent } from './components/generics/image-uploader/image-uploader.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CacheService } from './services/cache.service';
import { ReloadButtonComponent } from './components/generics/reload-button/reload-button.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SideNavMenuComponent } from './nav/side-nav-menu/side-nav-menu.component';
import { DataPrinterComponent } from './components/generics/data-printer/data-printer.component';
import { PhoneInputComponent } from './components/generics/phone-input/phone-input.component';
import { FormsModule } from '@angular/forms';
import { UsersComponent } from './components/users/users.component';
import { LoansComponent } from './components/loans/loans.component';
import { ReportsComponent } from './components/reports/reports.component';
import { UserDetailsComponent } from './components/users/user-details/user-details.component';
import { LoanDetailsComponent } from './components/loans/loan-details/loan-details.component';
import { ReportDetailsComponent } from './components/reports/report-details/report-details.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
        LoginComponent,
        HomeComponent,
        NavComponent,
        DataTableComponent,
        ImageUploaderComponent,
        ReloadButtonComponent,
        SideNavMenuComponent,
        DataPrinterComponent,
        PhoneInputComponent,
        SexSelectorComponent,
        UsersComponent,
        LoansComponent,
        ReportsComponent,
        UserDetailsComponent,
        LoanDetailsComponent,
        ReportDetailsComponent,
        ConfigurationsComponent,
        FeesListDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
        NgxDatatableModule
    ],
    providers: [
        UtilsService,
        AuthService,
        CacheService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    entryComponents: [
        ConfirmDialogComponent,
        FeesListDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

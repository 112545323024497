import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CacheService } from 'src/app/services/cache.service';

@Component({
    selector: 'app-reload-button',
    templateUrl: './reload-button.component.html',
    styleUrls: ['./reload-button.component.scss']
})
export class ReloadButtonComponent implements OnInit {

    @Output()
    afterReload = new EventEmitter();

    constructor(private cacheService: CacheService) { }

    ngOnInit() {
    }

    reload() {
        this.cacheService.invalidateAllCache();
        this.afterReload.emit();
    }
}

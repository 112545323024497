<app-nav titulo="Configuración" descripcion="Aquí podrá ver y modificar el valor de las variables utilizadas en la plataforma.">
    <div class="configurations">
        <div class="section configuration-parameters-section">
            <span class="section-title">
                Parámetros de configuración
            </span>
            <div class="configuration" *ngFor="let configuration of configurations">
                <span class="configuration-label">{{ configuration.description }}</span>
                <mat-form-field class="no-bottom-spacing-form-field no-label" appearance="outline">
                    <input matInput [(ngModel)]="configuration.value" [type]="configuration.type.toLowerCase()">
                </mat-form-field>
                <button mat-raised-button class="save-button" color="primary" (click)="updateConfiguration(configuration)">
                    Guardar
                </button>
            </div>
        </div>
        <div class="section">
            <span class="section-title">
                Niveles de riesgo
            </span>
            <app-data-table
                [columns]="riskLevelTableColumns"
                [rows]="riskLevelConfigurations"
                *ngIf="riskLevelConfigurations"
            ></app-data-table>
        </div>
    </div>
</app-nav>

<ng-template #editableCell let-rowIndex="rowIndex" let-row="row" let-column="column">
    <mat-form-field class="no-bottom-spacing-form-field no-label editable-cell-input" appearance="outline">
        <input matInput [(ngModel)]="row[column.prop]" [type]="column.templateParams.inputType">
    </mat-form-field>
</ng-template>

<ng-template #saveRow let-rowIndex="rowIndex" let-row="row" let-column="column">
    <button mat-raised-button class="save-risk-level-configuration-button" color="primary" (click)="updateRiskLevelConfiguration(row)">
        Guardar
    </button>
</ng-template>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export interface CacheItem<T> {
    date: number;
    value: T;
}

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    constructor(private http: HttpClient) { }

    get<T>(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>>;

    get<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T>;

    get<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: any;
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<any> {
        if (this.isCached(url) && this.isCacheValid(url)) {
            return new Observable<HttpResponse<T>>(observer => {
                observer.next(this.getCache<HttpResponse<T>>(url).value);
                observer.complete();
            });
        } else {
            return this.http.get<T>(url, options)
                .pipe(
                    tap(value => {
                        this.addCustomCache<HttpResponse<T>>(url, value);
                    })
                );
        }
    }

    public post<T>(url: string, data: any, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        return this.http.post<T>(url, data, options)
            .pipe(
                tap(_ => {
                    this.invalidateAllCache();
                })
            );
    }

    public delete<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        return this.http.delete<T>(url, options)
            .pipe(
                tap(_ => {
                    this.invalidateAllCache();
                })
            );
    }

    public addCustomCache<T>(url: string, value: T) {
        const cacheItem: CacheItem<T> = {
            date: Date.now(),
            value
        };
        localStorage.setItem(this.getStorageKey(url), JSON.stringify(cacheItem));
    }

    public invalidateAllCache() {
        const keys = Object.keys(localStorage)
            .filter(key => key.startsWith('{{cache}}'));

        for (const key of keys) {
            localStorage.removeItem(key);
        }
    }

    public invalidateUrl(url: string) {
        if (this.isCached(url)) {
            localStorage.removeItem(this.getStorageKey(url));
        }
    }

    private isCached(url: string) {
        return !!localStorage.getItem(this.getStorageKey(url));
    }

    private getStorageKey(url: string): string {
        return `{{cache}}{${ url }}`;
    }

    private isCacheValid(url: string): boolean {
        const cacheDate = this.getCache(url).date;
        const currentDate = Date.now();
        return currentDate - cacheDate < 8.64e+7;
    }

    private getCache<T>(url: string): CacheItem<T> {
        return JSON.parse(localStorage.getItem(this.getStorageKey(url))) as CacheItem<T>;
    }
}

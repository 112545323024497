import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    inputValue: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {titulo: string, descripcion?: string, input?: string, botonAceptar?: string, botonCancelar?: string}
    ) { }

    ngOnInit() {
        if (!this.data.descripcion) {
            this.data.descripcion = '';
        }
    }

    cerrarDialogo(resultado: boolean) {
        if (resultado === false || !this.data.input) {
            this.dialogRef.close(resultado);
        } else {
            this.dialogRef.close(this.inputValue ?? '');
        }
    }

}

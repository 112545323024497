import { UserService } from './../../services/user.service';
import { User } from './../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ErrorResponse } from '../../models/error.model';
import { UtilsService } from '../../services/utils.service';
import { Loan } from '../../models/loan.model';
import { PaginatorStatus, SortConfiguration, DataTableColumn, SortEvent, MenuButton } from '../generics/data-table/data-table.component';
import { SortDirection } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    paginatorStatus: PaginatorStatus = {
        itemsPerPage: 5,
        page: 0,
        totalElementCount: 0
    };

    columns: DataTableColumn[] = [
        { name: 'Fecha de creación', prop: 'added', type: 'datetime' },
        { name: 'Email', prop: 'email', type: 'email', cellClass: 'break-words' },
        { name: 'Teléfono', prop: 'phone' },
        { name: 'Permisos', prop: '', parseValue: (user: User) => user.profiles.includes('admin') ? 'Administrador' : 'Cliente' },
    ];

    sorts: SortConfiguration[] = [{ dir: SortDirection.desc, prop: 'added' }];

    menuButtons: MenuButton<Loan>[] = [
        { label: 'Ver detalles', action: loan => this.router.navigate(['usuarios', loan.id]) }
    ];

    users: User[];

    constructor(
        private userService: UserService,
        private utilsService: UtilsService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getUsers();
    }

    getUsers() {
        this.userService.getUsers(this.paginatorStatus.page, this.paginatorStatus.itemsPerPage, this.sorts, {})
            .subscribe(response => {
                this.paginatorStatus.totalElementCount = parseInt(response.headers.get('pagination-total-count') ?? '0', 10);
                this.users = response.body;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener usuarios', error.error?.message));
    }

    updateUsers(event: PageEvent) {
        this.paginatorStatus.itemsPerPage = event.pageSize;
        this.paginatorStatus.page = event.pageIndex;
        this.getUsers();
    }

    updateSort(event: SortEvent) {
        this.sorts = event.sorts;
        this.getUsers();
    }

}

import { ErrorResponse } from './../models/error.model';
import { Phone } from './../models/phone.model';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SortConfiguration, DataTableColumn } from './../components/generics/data-table/data-table.component';
import { AWSRequest } from './../models/aws-request.model';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { Router, UrlSerializer } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as moment from 'moment'; // npm install moment --save
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ExportToCsv } from 'export-to-csv';

export interface PaginatedResponse<T> {
    data: T;
    totalElementCount: number;
}

export type StringDict<ValueType = any> = { [key: string]: ValueType };

interface DocumentURLResponse {
    url: string;
    key: string;
    fields: StringDict;
}

export type DrawableType = 'any' | 'text' | 'number' | 'number-2' | 'number-4' | 'date' | 'datetime' | 'money' | 'percent' | 'phone' | 'email';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private mobile = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private serializer: UrlSerializer,
        private breakpointObserver: BreakpointObserver,
        private http: HttpClient
    ) {
        breakpointObserver.observe(['(max-width: 800px)']).subscribe(result => this.mobile = result.matches);
    }

    beautifyNumber(n: any, digits: number = 0): string {
        if (!(n instanceof Number)) {
            n = Number(n);
        }
        return Intl.NumberFormat('es-AR', { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(n);
    }

    beautifyText(text: any): string {
        if (text) {
            const firstMayus = text.charAt(0).toUpperCase() + text.slice(1);
            return firstMayus.replaceAll('_', ' ');
        }
    }

    beautifyDate(date: string, format = 'LLL'): string {
        const d = moment(date);
        d.locale('es');
        return d.format(format);
    }

    downLoadFile(response: any, filename: string) {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
    }

    downloadJson(object: any, filename: string) {
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(object, null, 4));
        const downloadLink = document.createElement('a');
        downloadLink.href = dataStr;
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
    }

    alert(title: string, description: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: title,
                descripcion: description,
                botonAceptar: 'Aceptar'
            }
        });
    }

    confirm(title: string, description: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: title,
                descripcion: description,
                botonCancelar: 'Cancelar',
                botonAceptar: 'Confirmar'
            }
        });
    }

    serializeUrl(urlArray: any[], queryParams: any) {
        const url = this.router.createUrlTree(urlArray, { queryParams });
        return this.serializer.serialize(url);
    }

    isMobile(): boolean {
        return this.mobile;
    }

    obtenerDecimales(numero: number, cantidadDeDecimales: number): string {
        return Math.floor((numero % 1) * Math.pow(10, cantidadDeDecimales)).toLocaleString('es-AR', {minimumIntegerDigits: cantidadDeDecimales});
    }

    paginatedRequest<T>(url, pageNumber: number, itemsPerPage: number): Observable<PaginatedResponse<T>> {
        return this.http.get<T>(url, { observe: 'response', headers: {
            'cantidad-registros': itemsPerPage.toString(),
            'numero-pagina': pageNumber.toString()
        }}).pipe(map(response => {
            return {
                data: response.body,
                totalElementCount: Number.parseInt(response.headers.get('total-registros'), 10)
            };
        }));
    }

    generateSortQueryParam(sorts: SortConfiguration[]) {
        return sorts
            .filter(config => !!config.dir && !!config.prop)
            .map(config => (config.dir === 'asc' ? '+' : '-') + config.prop.replace('.', '_'))
            .join(',');
    }

    numberFormValidator(control: AbstractControl): ValidationErrors {
        if (isNaN(control.value)) {
            return { number: true };
        }
        return {};
    }

    autocompleteSelectedValidator(control: AbstractControl): ValidationErrors {
        return typeof control.value === 'object' ? undefined : { notSelected: true };
    }

    drawPhone(phone?: Phone) {
        if (!phone?.codigoPais || !phone?.codigoArea || !phone.numero) return undefined;
        return `(+${phone.codigoPais}) ${phone.codigoArea} - ${String(phone.numero).length === 8 ? String(phone.numero).substring(0, 4) + ' - ' + String(phone.numero).substring(4, 8) : phone.numero}`;
    }

    parsePhone(phoneString: string) {
        const values = phoneString.split('-').map(x => parseInt(x, 10));
        if (values && values.length === 3 && values.every(x => !isNaN(x))) {
            return {
                codigoPais: values[0],
                codigoArea: values[1],
                numero: values[2]
            } as Phone;
        } else {
            throw new Error(`Error al parsear teléfono, un ejemplo de teléfono correcto es: +54-11-22323223.`);
        }
    }

    stringMatcher(a: string, b: string) {
        return this.removeAccentMarks(a).toLowerCase() === this.removeAccentMarks(b).toLowerCase();
    }

    removeAccentMarks(str: string) {
        return str
            .replace('á', 'a')
            .replace('é', 'e')
            .replace('í', 'i')
            .replace('ó', 'o')
            .replace('ú', 'u')
            .replace('Á', 'A')
            .replace('É', 'E')
            .replace('Í', 'I')
            .replace('Ó', 'O')
            .replace('Ú', 'U');
    }

    uploadFileToAWS<ReturnType>(file: File, getUrlEndpoint: string, reportUploadEndpoint: string, getUrlExtraParams: any, reportUploadExtraParams: any) {
        return new Promise<ReturnType>((resolve, reject) => {
            this.http.post<DocumentURLResponse>(getUrlEndpoint, { fileName: file.name }).subscribe(urlData => {
                const form = new FormData();
                Object.keys(urlData.fields).forEach(key => form.append(key, urlData.fields[key]));
                form.append('file', file, file.name);
                this.http.post(urlData.url, form).subscribe(_ => {
                    this.http.post<ReturnType>(reportUploadEndpoint, Object.assign({ fileName: file.name, key: urlData.key }, reportUploadExtraParams)).subscribe(
                        response => resolve(response),
                        (error: ErrorResponse) => reject(error)
                    );
                });
            }, (error: ErrorResponse) => reject(error));
        });
    }

    downloadAWSFile(request: AWSRequest) {
        this.http.get(request.url, { responseType: 'arraybuffer', observe: 'response' }).subscribe(response => {
            this.downLoadFile(response.body, request.fileName);
        });
    }

    parseByType(value: any, type: DrawableType) {
        if (type === 'text') {
            return this.beautifyText(value);
        } else if (type === 'number') {
            return this.beautifyNumber(value, 0);
        } else if (type === 'number-2') {
            return this.beautifyNumber(value, 2);
        } else if (type === 'number-4') {
            return this.beautifyNumber(value, 4);
        } else if (type === 'date') {
            return this.beautifyDate(value, 'L');
        } else if (type === 'datetime') {
            return this.beautifyDate(value, 'L HH:mm');
        } else if (type === 'money') {
            return '$' + this.beautifyNumber(value, 2);
        } else if (type === 'percent') {
            return this.beautifyNumber(value, 0) + '%';
        } else if (type === 'phone') {
            const phone: Phone = value;
            return `<a class="link" href="tel:+${ phone.codigoPais }${ phone.codigoArea }${ phone.numero }">${ this.drawPhone(phone) }</a>`;
        } else if (type === 'email') {
            return `<a class="link" href="mailto:${ value }">${ value }</a>`;
        }
        return value;
    }

    tableToCSV(rows: any[], columns: DataTableColumn[], filename: string) {
        const data = rows.map(row => {
            const ret: any = {};
            columns.forEach(column => {
                const value = column.prop ? row[column.prop] : undefined;
                let result: any;
                if (value === undefined || value === null || value === '') {
                    result = '-';
                } else {
                    result = value;
                    if (column.parseValue) {
                        result = column.parseValue(result, column);
                    }
                    result = this.parseByType(result, column.type);
                }
                ret[column.name] = result;
            });
            return ret;
        });
        const options = {
            filename,
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalSeparator: '.',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
}

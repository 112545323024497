<app-nav titulo="Usuarios" descripcion="Aquí podrá ver la lista con todos los usuarios registrados en la plataforma.">
    <div class="users">
        <app-data-table
            [rows]="users"
            [columns]="columns"
            [paginatorStatus]="paginatorStatus"
            [externalPagination]="true"
            [menuButtons]="menuButtons"
            (updateRows)="updateUsers($event)"
            (sortFn)="updateSort($event)"
        ></app-data-table>
    </div>
</app-nav>

import { Phone } from './../../../models/phone.model';
import { Component, Input, ElementRef, Self, Optional, OnDestroy, DoCheck } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: PhoneInputComponent }
    ],
    // tslint:disable-next-line: no-host-metadata-property
    host: {
        '[class.floating]': 'shouldLabelFloat',
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
    }
})
export class PhoneInputComponent implements ControlValueAccessor, MatFormFieldControl<Phone>, OnDestroy, DoCheck {

    get empty() {
        const {value: {codigoPais, codigoArea, numero}} = this.formGroup;
        return !codigoPais && !codigoArea && !numero;
    }

    get shouldLabelFloat() { return this.focused || !this.empty; }

    @Input()
    get placeholder(): string { return this.placeholderValue; }
    set placeholder(value: string) {
        this.placeholderValue = value;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean { return this.requiredValue; }
    set required(value: boolean) {
        this.requiredValue = value;
        this.stateChanges.next();
    }

    @Input()
    get value(): Phone | null {
        if (this.formGroup.valid && !this.empty) {
            const { value } = this.formGroup;
            return value;
        }
        return null;
    }
    set value(tel: Phone | null) {
        const value = tel || {codigoPais: '', codigoArea: '', numero: ''};
        this.formGroup.setValue(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean { return this.disabledValue; }
    set disabled(value: boolean) {
        this.disabledValue = value;
        this.disabledValue ? this.formGroup.disable() : this.formGroup.enable();
        this.stateChanges.next();
    }

    // tslint:disable-next-line: variable-name
    static ngAcceptInputType_disabled: boolean | string | null | undefined;
    // tslint:disable-next-line: variable-name
    static ngAcceptInputType_required: boolean | string | null | undefined;

    formGroup = new FormGroup({
        codigoPais: new FormControl('', []),
        codigoArea: new FormControl('', []),
        numero: new FormControl('', []),
    });
    stateChanges = new Subject<void>();
    focused = false;
    errorState = false;
    controlType = 'app-phone-input';
    describedBy = '';

    @Input()
    id: string;
    private placeholderValue = '';
    private requiredValue = false;
    private disabledValue = false;
    onChange = (_: any) => {};
    onTouched = () => {};

    constructor(
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl
    ) {

        focusMonitor.monitor(elementRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef);
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input')?.focus();
        }
    }

    writeValue(obj: Phone) {
        if (obj !== null && obj !== undefined) {
            this.value = obj;
        }
    }

    registerOnChange(fn: (p: Phone) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }
}

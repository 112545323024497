import { UserService } from './../../../services/user.service';
import { User } from './../../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report.service';
import { Scoring } from '../../../models/scoring.model';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../generics/data-printer/data-printer.component';
import { ErrorResponse } from '../../../models/error.model';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

    categories: Category[] = [];
    user: User;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.userService.getUserById(params.id).subscribe(user => {
                    this.user = user;
                    this.generateCategories(user);
                }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener datos del usuario', error.error?.message));
            }
        });
    }

    generateCategories(user: User) {
        this.categories = [
            { name: 'Información general', items: [
                { label: 'Fecha de creación', value: user.added, type: 'datetime' },
                { label: 'Email', value: user.email, type: 'email' },
                { label: 'Teléfono', value: user.phone },
                { label: 'Permisos', value: user.profiles.includes('admin') ? 'Administrador' : 'Cliente' },
                { label: 'Última actualización', value: user.updated, type: 'datetime' },
            ]}
        ];
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { SideNavMenu } from 'src/app/models/side-nav-menu.model';

@Component({
    selector: 'app-side-nav-menu',
    templateUrl: './side-nav-menu.component.html',
    styleUrls: ['./side-nav-menu.component.scss']
})
export class SideNavMenuComponent implements OnInit {

    @Input()
    menu: SideNavMenu;

    constructor() {
    }

    ngOnInit() {
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { SideNavMenu } from '../models/side-nav-menu.model';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    public backofficeConfig = environment;
    public username = 'Usuario';
    public showSideBar = true;

    public sideNavMenus: SideNavMenu[] = [
        { icon: 'home', text: 'Home', redirect: '/home' },
        { icon: 'person', text: 'Usuarios', redirect: '/usuarios' },
        { icon: 'attach_money', text: 'Créditos', redirect: '/prestamos' },
        { icon: 'content_paste', text: 'Informes', redirect: '/informes' },
        { icon: 'settings', text: 'Configuración', redirect: '/configuracion' },
    ];

    public routerSteps: string[] = [];

    @Input()
    titulo = '';

    @Input()
    descripcion = '';

    constructor(
        private authService: AuthService,
        private utilsService: UtilsService,
        private router: Router
    ) { }

    ngOnInit() {
        const user = this.authService.getUser();
        if (user) {
            this.username = this.utilsService.beautifyText(user.email ?? 'Usuario');
        }
        if (this.utilsService.isMobile()) {
            this.showSideBar = false;
        }
        this.manageRouterHistory();
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    manageRouterHistory() {
        this.routerSteps = this.router.url.split('/').filter(route => !!route);
    }

    redirectTo(index: number) {
        const url = '/' + this.routerSteps.slice().splice(0, index + 1).join('/');
        this.router.navigateByUrl(url).catch(() => {});
    }
}

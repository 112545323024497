import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { LoanDetailsComponent } from './components/loans/loan-details/loan-details.component';
import { UserDetailsComponent } from './components/users/user-details/user-details.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LoansComponent } from './components/loans/loans.component';
import { UsersComponent } from './components/users/users.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { HomeComponent } from './components/home/home.component';
import { ReportDetailsComponent } from './components/reports/report-details/report-details.component';

export function parentRoute(path: string, children: Route[], pathMatch: 'prefix' | 'full' = 'prefix') {
    return {
        path,
        pathMatch,
        children
    };
}

export function route(path: string, component: any, requireAuth: boolean, pathMatch: 'prefix' | 'full' = 'prefix') {
    return {
        path,
        component,
        pathMatch,
        canActivate: [AuthService],
        data: {
            requireAuth
        }
    };
}

const routes: Routes = [
    route('login', LoginComponent, false),
    route('home', HomeComponent, true),
    parentRoute('usuarios', [
        route('', UsersComponent, true, 'full'),
        route(':id', UserDetailsComponent, true)
    ]),
    parentRoute('prestamos', [
        route('', LoansComponent, true, 'full'),
        route(':id', LoanDetailsComponent, true)
    ]),
    parentRoute('informes', [
        route('', ReportsComponent, true, 'full'),
        route(':id', ReportDetailsComponent, true)
    ]),
    route('configuracion', ConfigurationsComponent, true),
    { path: '**', pathMatch: 'full', redirectTo: '/home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import { Configuration, RiskLevelConfiguration } from './../models/configuration.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        private http: HttpClient
    ) { }

    getConfigurations() {
        return this.http.get<Configuration[]>(`/configurations`);
    }

    updateConfiguration(configuration: Configuration) {
        return this.http.put<Configuration>(`/configurations`, configuration);
    }

    getRiskLevelConfigurations() {
        return this.http.get<RiskLevelConfiguration[]>(`/configurations/risk-level`);
    }

    updateRiskLevelConfiguration(configuration: RiskLevelConfiguration) {
        return this.http.put<RiskLevelConfiguration>(`/configurations/risk-level`, configuration);
    }
}

<app-nav titulo="Detalles del usuario" descripcion="Estos son todos los datos que tenemos sobre el usuario seleccionado.">
    <div class="user-details" *ngIf="user">
        <app-data-printer [categories]="categories"></app-data-printer>
        <div class="buttons">
            <button mat-raised-button color="primary" class="loans-button" routerLink="/prestamos" [queryParams]="{ userId: user.id }">
                Ver créditos asociados
            </button>
            <button mat-raised-button color="primary" class="reports-button" routerLink="/informes" [queryParams]="{ userId: user.id }">
                Ver informes asociados
            </button>
        </div>
    </div>
</app-nav>

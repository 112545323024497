<app-nav titulo="Informes" descripcion="Aquí podrá ver la lista con todos los scorings que fueron ejecutados en la plataforma y sus respectivos detalles de aprobación/rechazo.">
    <div class="reports">
        <app-data-table
            [rows]="reports"
            [columns]="columns"
            [paginatorStatus]="paginatorStatus"
            [externalPagination]="true"
            [menuButtons]="menuButtons"
            (updateRows)="updateReports($event)"
            (sortFn)="updateSort($event)"
        ></app-data-table>
    </div>
</app-nav>

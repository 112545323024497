<div class="login">
    <div class="login-box-container" *ngIf="showingPage === 'username'">
        <form class="login-box" [formGroup]="usernameFG" (ngSubmit)="showPassword()">
            <h1 class="project-title text">
                {{ backofficeConfig.projectTitle }}
            </h1>
            <h2 class="login-title text">
                Login
            </h2>
            <mat-form-field class="login-field">
                <mat-label>Ingresa tu usuario</mat-label>
                <input matInput type="text" formControlName="username" id="usernameInput" required>
                <mat-error *ngIf="usernameFG.controls['username'].hasError('required')">
                    Por favor ingrese un usuario
                </mat-error>
            </mat-form-field>
            <div class="login-button-container">
                <button mat-button color="primary" class="login-button" [disabled]="!usernameFG.valid">
                    Siguiente
                </button>
            </div>
        </form>
    </div>
    <div class="login-box-container" *ngIf="showingPage === 'password'">
        <form class="login-box password-box-animation" [formGroup]="passwordFG" (ngSubmit)="login()">
            <h1 class="project-title text">
                {{ backofficeConfig.projectTitle }}
            </h1>
            <h2 class="login-title text">
                Login
            </h2>
            <mat-form-field class="login-field">
                <mat-label>Ingresa tu contraseña</mat-label>
                <input matInput type="password" formControlName="password" id="passwordInput" required>
                <mat-error *ngIf="passwordFG.controls['password'].hasError('required')">
                    Por favor ingrese una contraseña
                </mat-error>
            </mat-form-field>
            <div class="login-button-container">
                <button mat-button class="login-button" type="button" (click)="showUsername()">
                    Volver
                </button>
                <button mat-button color="primary" class="login-button" [disabled]="!passwordFG.valid">
                    Iniciar sesión
                </button>
            </div>
        </form>
    </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

    @Input()
    url: string | ArrayBuffer = '';

    @Output()
    file = new EventEmitter<File>();

    @ViewChild('input')
    input: ElementRef;

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
    }

    update(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length) {
            this.file.emit(input.files[0]);
        } else {
            this.file.emit(null);
        }
        this.readURL(input);
    }

    readURL(input: HTMLInputElement): void {
        if (input.files && input.files.length) {
            const file = input.files[0];

            const reader = new FileReader();
            reader.onload = e => this.url = reader.result;

            reader.readAsDataURL(file);
        }
    }

    delete() {
        this.file.emit(null);
        this.url = '';
        this.changeDetector.detectChanges();
        this.input.nativeElement.value = '';
    }
}

<div class="fees-list-dialog">
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <span class="title">
        Lista de cuotas
    </span>
    <div class="download-buttons">
        <button mat-icon-button class="download-csv" (click)="downloadCSV()">
            <img src="/assets/images/download-csv.svg" alt="" class="download-csv-icon">
        </button>
    </div>
    <div class="table-container">
        <app-data-table
            [rows]="data.fees"
            [columns]="columns"
            [columnMode]="ColumnMode.standard"
            class="table"
        ></app-data-table>
    </div>
</div>

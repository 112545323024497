import { DataTableColumn } from './../generics/data-table/data-table.component';
import { Configuration, RiskLevelConfiguration } from './../../models/configuration.model';
import { ErrorResponse } from './../../models/error.model';
import { UtilsService } from './../../services/utils.service';
import { ConfigurationService } from './../../services/configuration.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-configurations',
    templateUrl: './configurations.component.html',
    styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {

    configurations?: Configuration[];
    riskLevelConfigurations?: RiskLevelConfiguration[];

    riskLevelTableColumns: DataTableColumn[] = [];

    @ViewChild('editableCell', { static: true })
    editableCellTemplate: TemplateRef<any>;

    @ViewChild('saveRow', { static: true })
    saveRowTemplate: TemplateRef<any>;

    constructor(
        private configurationService: ConfigurationService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.configurationService.getConfigurations().subscribe(configurations => {
            this.configurations = configurations;
            this.configurations.sort((a, b) => a.description < b.description ? -1 : 1);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener configuraciones', error.error?.message));

        this.configurationService.getRiskLevelConfigurations().subscribe(configurations => {
            this.riskLevelConfigurations = configurations;
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener niveles de riesgo', error.error?.message));

        this.riskLevelTableColumns = [
            { name: 'Nivel de riesgo', prop: 'levelRisk', sortable: false },
            { name: 'Scoring mínimo', prop: 'scoreMin', sortable: false, template: this.editableCellTemplate, templateParams: { inputType: 'number' } },
            { name: 'Scoring máximo', prop: 'scoreMax', sortable: false, template: this.editableCellTemplate, templateParams: { inputType: 'number' } },
            { name: 'TNA', prop: 'tna', sortable: false, template: this.editableCellTemplate, templateParams: { inputType: 'number' } },
            { name: 'Factor de cotización', prop: 'quoteFactor', sortable: false, template: this.editableCellTemplate, templateParams: { inputType: 'number' } },
            { name: 'Factor de ingreso', prop: 'incomeFactor', sortable: false, template: this.editableCellTemplate, templateParams: { inputType: 'number' } },
            { name: '', prop: '', sortable: false, template: this.saveRowTemplate },
        ];
    }

    updateConfiguration(configuration: Configuration) {
        this.configurationService.updateConfiguration(configuration).subscribe(
            _ => this.utilsService.alert('Configuración actualizada correctamente', ''),
            (error: ErrorResponse) => this.utilsService.alert('Error al actualizar configuración', error.error?.message)
        );
    }

    updateRiskLevelConfiguration(configuration: RiskLevelConfiguration) {
        this.configurationService.updateRiskLevelConfiguration(configuration).subscribe(
            _ => this.utilsService.alert('Configuración actualizada correctamente', ''),
            (error: ErrorResponse) => this.utilsService.alert('Error al actualizar configuración', error.error?.message)
        );
    }
}

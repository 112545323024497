import { Phone } from './../../../models/phone.model';
import { DrawableType, UtilsService } from './../../../services/utils.service';
import { Component, OnInit, Input, TemplateRef } from '@angular/core';

export interface Category {
    name: string;
    items: InfoItem[];
    width?: string;
    minWidth?: string;
}

export interface InfoItem {
    label?: string;
    value?: any;
    template?: TemplateRef<any>;
    templateParams?: any;
    type?: DrawableType;
}

@Component({
    selector: 'app-data-printer',
    templateUrl: './data-printer.component.html',
    styleUrls: ['./data-printer.component.scss']
})
export class DataPrinterComponent implements OnInit {

    @Input()
    categories: Category[];

    constructor(
        private utilsService: UtilsService
    ) { }

    ngOnInit() {
    }

    parseByType(item: InfoItem) {
        if ((item.value === undefined || item.value === null || item.value === '') && !item.template) return ' - ';
        return this.utilsService.parseByType(item.value, item.type);
    }
}

import { User } from './../models/user.model';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { SortConfiguration } from '../components/generics/data-table/data-table.component';
import { ID } from '../models/id.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService
    ) { }

    getUsers(page: number, itemsPerPage: number, sorts: SortConfiguration[], extraParams: any) {
        const url = '/users';
        const headers = {};
        const params = {
            'sort-by': this.utilsService.generateSortQueryParam(sorts),
            'page-number': String(page),
            'page-size': String(itemsPerPage)
        };

        Object.assign(params, JSON.parse(JSON.stringify(extraParams)));

        return this.http.get<User[]>(url, { headers, observe: 'response', params });
    }

    getUserById(id: ID) {
        return this.http.get<User>(`/users/${id}`);
    }
}

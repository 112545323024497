<app-nav titulo="Créditos" descripcion="Aquí podrá ver la lista con todos los créditos (ya sea en proceso o activos) generados por la plataforma.">
    <div class="loans">
        <app-data-table
            [rows]="loans"
            [columns]="columns"
            [paginatorStatus]="paginatorStatus"
            [externalPagination]="true"
            [menuButtons]="menuButtons"
            (updateRows)="updateLoans($event)"
            (sortFn)="updateSort($event)"
        ></app-data-table>
    </div>
</app-nav>

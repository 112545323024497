import { ID } from './../../models/id.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ErrorResponse } from './../../models/error.model';
import { UtilsService } from './../../services/utils.service';
import { LoanService } from './../../services/loan.service';
import { Loan, LoanState } from './../../models/loan.model';
import { PaginatorStatus, SortConfiguration, DataTableColumn, SortEvent, MenuButton } from './../generics/data-table/data-table.component';
import { Component, OnInit } from '@angular/core';
import { SortDirection } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-loans',
    templateUrl: './loans.component.html',
    styleUrls: ['./loans.component.scss']
})
export class LoansComponent implements OnInit {

    paginatorStatus: PaginatorStatus = {
        itemsPerPage: 5,
        page: 0,
        totalElementCount: 0
    };

    columns: DataTableColumn[] = [
        { name: 'Fecha de creación', prop: 'added', type: 'datetime' },
        { name: 'Estado', prop: 'state', parseValue: (status: LoanState) => this.loanService.translateStatus(status) },
        { name: 'Creado por', prop: 'user.email', type: 'email', cellClass: 'break-words' },
        { name: 'Monto', prop: 'requestedAmount', type: 'money' },
        { name: 'Nro. Cuotas', prop: 'term', type: 'number' },
        { name: 'Tasa', prop: 'nominalRate', type: 'percent', parseValue: value => Number(value) * 100 as any },
    ];

    sorts: SortConfiguration[] = [{ dir: SortDirection.desc, prop: 'added' }];

    menuButtons: MenuButton<Loan>[] = [
        { label: 'Ver detalles', action: loan => this.router.navigate(['prestamos', loan.id]) }
    ];

    loans: Loan[];

    constructor(
        private loanService: LoanService,
        private utilsService: UtilsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.getLoans(params.userId);
        });
    }

    getLoans(userId?: ID) {
        this.loanService.getLoans(this.paginatorStatus.page, this.paginatorStatus.itemsPerPage, this.sorts, userId ? { 'user-id': userId } : {})
            .subscribe(response => {
                this.paginatorStatus.totalElementCount = parseInt(response.headers.get('pagination-total-count') ?? '0', 10);
                this.loans = response.body;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener créditos', error.error?.message));
    }

    updateLoans(event: PageEvent) {
        this.paginatorStatus.itemsPerPage = event.pageSize;
        this.paginatorStatus.page = event.pageIndex;
        this.getLoans();
    }

    updateSort(event: SortEvent) {
        this.sorts = event.sorts;
        this.getLoans();
    }

}

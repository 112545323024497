import { ErrorResponse } from './../../models/error.model';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public backofficeConfig = environment;
    public showingPage: 'username' | 'password' = 'username';

    private returnUrl: string;

    public usernameFG = new FormGroup({
        username: new FormControl('', [Validators.required]),
    });

    public passwordFG = new FormGroup({
        password: new FormControl('', [Validators.required])
    });

    constructor(
        private changeDetector: ChangeDetectorRef,
        private authService: AuthService,
        private utilsService: UtilsService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.returnUrl) {
                this.returnUrl = params.returnUrl;
            }
        });
    }

    showPassword() {
        this.showingPage = 'password';
        this.changeDetector.detectChanges();
        document.getElementById('passwordInput').focus();
    }

    showUsername() {
        this.showingPage = 'username';
        this.changeDetector.detectChanges();
        document.getElementById('usernameInput').focus();
    }

    login() {
        this.authService.login(this.usernameFG.value.username, this.passwordFG.value.password).subscribe(
            session => {
                this.authService.saveToken(session.token);
                this.authService.saveUser(session.usuario);
                if (this.returnUrl) {
                    this.router.navigate([this.returnUrl]);
                } else {
                    this.router.navigate(['']);
                }
            }, (error: ErrorResponse) => this.utilsService.alert('Error iniciando sesión', error.error?.message)
        );
    }
}

<div class="page-container">
    <div class="top-nav">
        <div class="icons-container">
            <button mat-icon-button class="menu-button" (click)="showSideBar = !showSideBar">
                <mat-icon class="menu-button-icon">menu</mat-icon>
            </button>
            <span class="project-title">
                {{ backofficeConfig.projectTitle }}
            </span>
        </div>
        <div class="icons-container">
            <span class="hello text">
                Hola! {{ username }}
            </span>
            <button mat-icon-button class="logout menu-button" (click)="logout()">
                <mat-icon class="menu-button-icon">power_settings_new</mat-icon>
            </button>
        </div>
    </div>
    <div class="page-bottom">
        <div class="side-nav" *ngIf="showSideBar">
            <app-side-nav-menu [menu]="menu" *ngFor="let menu of sideNavMenus"></app-side-nav-menu>
        </div>
        <div class="page-content">
            <div class="page-title" *ngIf="titulo">
                <div class="stepper" [style.visibility]="routerSteps.length < 2 ? 'hidden' : 'visible'">
                    <div class="step" *ngFor="let route of routerSteps; let i = index">
                        <span class="step-link" (click)="redirectTo(i)">{{ route }}</span>
                        <mat-icon class="separator">chevron_right</mat-icon>
                    </div>
                </div>
                <h1>{{ titulo }}</h1>
                <h3>{{ descripcion }}</h3>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>

<app-nav titulo="Detalles del scoring" descripcion="Estos son los resultados del scoring seleccionado y los parámetros utilizados para tomar esa decisión.">
    <div class="report-details" *ngIf="report">
        <div class="buttons">
            <button mat-raised-button color="primary" class="loan-button" routerLink="/prestamos/{{report?.loan?.id}}">
                Ver crédito asociado
            </button>
            <button mat-raised-button color="primary" class="download-pyp-button" (click)="utilsService.downloadJson(report.variables, 'Resultado.json')">
                Descargar datos crudos
            </button>
        </div>
        <app-data-printer [categories]="categories"></app-data-printer>
    </div>
</app-nav>


<div class="data-printer" *ngIf="categories">
    <div class="category" *ngFor="let category of categories" [style.minWidth]="category.minWidth" [style.width]="category.width">
        <h3 class="category-name">
            {{ category.name }}
        </h3>
        <div class="item" *ngFor="let item of category.items">
            <span class="label" *ngIf="item.label">{{ item.label }}:</span>
            <span class="value" [innerHTML]="parseByType(item)"></span>
            <ng-container [ngTemplateOutlet]="item.template" [ngTemplateOutletContext]="item.templateParams ? item.templateParams : {}" *ngIf="item.template"></ng-container>
        </div>
    </div>
</div>

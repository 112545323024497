import { FeesListDialogComponent } from './../../../dialogs/fees-list-dialog/fees-list-dialog.component';
import { Plan } from './../../../models/plan.model';
import { Document, DocumentType } from './../../../models/document.model';
import { ID } from './../../../models/id.model';
import { ConfirmDialogComponent } from './../../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { Loan } from './../../../models/loan.model';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { LoanService } from './../../../services/loan.service';
import { Category } from './../../generics/data-printer/data-printer.component';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ErrorResponse } from '../../../models/error.model';

@Component({
    selector: 'app-loan-details',
    templateUrl: './loan-details.component.html',
    styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {

    categories: Category[] = [];
    loan: Loan;
    plan: Plan;
    documents: Document[];

    @ViewChild('downloadDocument', { static: true })
    downloadDocumentTemplate: TemplateRef<any>;

    constructor(
        private loanService: LoanService,
        private route: ActivatedRoute,
        private utilsService: UtilsService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.getLoan(params.id);
            }
        });
    }

    getLoan(id: ID) {
        this.loanService.getLoanById(id).subscribe(loan => {
            this.loan = loan;
            this.generateCategories(loan);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener crédito', error.error?.message));
        this.loanService.getLoanDocuments(id).subscribe(documents => {
            this.documents = documents;
            if (this.loan) {
                this.generateCategories(this.loan);
            }
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener crédito', error.error?.message));
        this.loanService.getPlan(id).subscribe(plan => {
            this.plan = plan;
            if (this.loan) {
                this.generateCategories(this.loan);
            }
        }, (error: ErrorResponse) => this.plan = undefined);
    }

    generateCategories(loan: Loan) {
        this.categories = [
            { name: 'Información general', items: [
                { label: 'Estado', value: this.loanService.translateStatus(loan.state) },
                { label: 'Cantidad de cuotas', value: loan.term, type: 'number' },
                { label: 'Fecha de creación', value: loan.added, type: 'datetime' },
                { label: 'Fecha de confirmación', value: loan.requestDate, type: 'datetime' },
                { label: 'Fecha de depósito', value: loan.depositDate, type: 'datetime' },
                { label: 'Última actualización', value: loan.updated, type: 'datetime' },
            ]}, { name: 'Montos', items: [
                { label: 'Monto', value: loan.requestedAmount, type: 'money' },
                { label: 'TNA', value: Number(loan.nominalRate) * 100, type: 'percent' },
                { label: 'Tasa de IVA', value: Number(loan.taxRate) * 100, type: 'percent' },
                { label: 'Tasa de comisiones', value: Number(loan.comissionRate) * 100, type: 'percent' },
                { label: 'Tasa de gastos', value: Number(loan.expenseRate) * 100, type: 'percent' },
            ]}, { name: 'Datos del solicitante', items: [
                { label: 'Email', value: loan.user?.email, type: 'email' },
                { label: 'Teléfono', value: loan.user?.phone, type: 'any' },
                { label: 'Cuenta bancaria', value: loan.bankAccount },
                { label: 'Tipo de ingresos', value: loan.incomeType, type: 'text' },
            ]},
        ];
        if (this.documents?.length) {
            this.categories.push({
                name: 'Documentación',
                items: this.documents.map(document => ({ label: this.translateDocumentType(document.type), template: this.downloadDocumentTemplate, templateParams: { document } }))
            });
        }
    }

    translateDocumentType(type: DocumentType) {
        if (type === 'id_front') {
            return 'DNI Frente';
        } else if (type === 'id_back') {
            return 'DNI Dorso';
        } else if (type === 'proof_of_funds') {
            return 'Recíbo de sueldo';
        } else if (type === 'signature') {
            return 'Firma';
        } else if (type === 'selfie') {
            return 'Selfie';
        } else if (type === 'other') {
            return 'Otro';
        }
    }

    downloadDoc(document: Document) {
        this.loanService.downloadDocument(this.loan.id, document.id).subscribe(awsRequest => {
            this.utilsService.downloadAWSFile(awsRequest);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al descargar archivo', error.error?.message));
    }

    approve() {
        this.loanService.approveRevision(this.loan.id).subscribe(_ => {
            this.utilsService.alert('Crédito aprobado correctamente', 'Ya le avisamos al cliente que puede continuar con su crédito.');
            this.getLoan(this.loan.id);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al aprobar crédito', error.error?.message));
    }

    reject() {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: '¿Está seguro que desea rechazar el crédito?',
                descripcion: 'Por favor ingrese la razón del rechazo.',
                input: 'Razón',
                botonAceptar: 'Aceptar',
                botonCancelar: 'Cancelar'
            }
        });
        dialog.afterClosed().subscribe((reason: string | false) => {
            if (reason !== false) {
                this.loanService.rejectRevision(this.loan.id, reason).subscribe(_ => {
                    this.utilsService.alert('Crédito rechazado correctamente', '');
                    this.getLoan(this.loan.id);
                }, (error: ErrorResponse) => this.utilsService.alert('Error al rechazar crédito', error.error?.message));
            }
        });
    }

    reportTransfer() {
        this.loanService.reportTransfer(this.loan.id).subscribe(_ => {
            this.utilsService.alert('Depósito informado correctamente', 'Ya le avisamos al cliente que puede continuar con su crédito.');
            this.getLoan(this.loan.id);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al informar depósito', error.error?.message));
    }

    openFeesModal() {
        if (this.plan?.fees) {
            this.dialog.open(FeesListDialogComponent, { data: { fees: this.plan.fees } });
        }
    }
}
